import React, { useEffect, useState } from 'react';
import { Button } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { useWalletStorage, ConnectorNames } from '../../../service';
import kardiaIcon from '../../../resources/kardia_logo_icon.png';
import metamaskIcon from '../../../resources/metamask.png';
import './accessWallet.css';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useAuth } from '../../../hooks';


const WalletExtensionConnect = () => {
    const history = useHistory();
    const setWalletStored = useWalletStorage(() => history.push('/wallet/dashboard'))[1];
    const { login } = useAuth()
    const { account } = useWeb3React<Web3Provider>()
    const [connectorName, setConnectorName] = useState('')

    const walletConnect = (connectorName: ConnectorNames) => {
        login(connectorName);
        setConnectorName(connectorName)
    }

    useEffect(() => {
        if(account) {
            setWalletStored({
                privatekey: '',
                address: account,
                isAccess: true,
                externalWallet: true,
                connectorName: connectorName
            });
        }
        // eslint-disable-next-line
    }, [account, connectorName])

    return (
        <div>
        <Button size="lg" block onClick={() => walletConnect(ConnectorNames.KAI)} style={{whiteSpace: 'break-spaces'}} >
            <img src={kardiaIcon} className="kardia-extension-wallet-icon" alt="" /> 
            <span>Kardia Extension Wallet</span>
        </Button>
        <Button size="lg" block onClick={() => walletConnect(ConnectorNames.Injected)} style={{whiteSpace: 'break-spaces'}} >
            <img src={metamaskIcon} className="kardia-extension-wallet-icon" alt="" style={{padding: '10px'}} /> 
            <span>Metamask Wallet</span>
        </Button>
        </div>
    )
}

export default WalletExtensionConnect; 