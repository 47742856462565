import { Alert } from 'rsuite';
import Web3 from 'web3';
import KRC20_API from '../resources/smc-compile/krc20-abi.json'
import { cellValue, cellValueKRC20, ShowNotify, calGasprice } from '../common';
import kardiaClient from '../plugin/kardia-dx';
import { GasMode } from '../enum';
declare global {
    interface Window {
        kardiachain: any;
        web3: any;
    }
}

const kardiaExtensionWalletEnabled = () => {
    if (window.kardiachain) {
        window.web3 = new Web3(window.kardiachain);
        if (window.kardiachain.isKaiWallet) {
            window.kardiachain.enable();
            return true;
        }
    }
    return false;
}

const generateTxForEW = async (toAddress: string, amount: number, gasPrice: GasMode, gasLimit: number) => {
    if (!kardiaExtensionWalletEnabled()) {
        Alert.error("Please install the Kardia Extension Wallet to access.", 5000)
    } else {
        try {

            const accounts = await window.web3.eth.getAccounts();
            const cellAmountDel = amount ? cellValue(amount) : 0;
            if (accounts && accounts[0]) {
                const kardiaTransaction = kardiaClient.transaction;

                const _gasPrice = await calGasprice(gasPrice)
                const response = await kardiaTransaction.sendTransactionToExtension({
                    from: accounts[0],
                    gasPrice: _gasPrice,
                    gas: gasLimit,
                    value: cellAmountDel,
                    to: toAddress,
                }, true);
                
                ShowNotify(response);      

            } else {
                Alert.error("Please login Kardia Extension Wallet.", 5000)
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const sendKRC20ByExtension = async (toAddress: string, amount: number, gasPrice: GasMode, gasLimit: number, tokenContract: string, decimal: any) => {
    if (!kardiaExtensionWalletEnabled()) {
        Alert.error("Please install the Kardia Extension Wallet to access.", 5000)
    } else {
        try {
            const accounts = await window.web3.eth.getAccounts();
            const cellAmountDel = amount ? cellValueKRC20(amount, decimal) : 0;
            const kardiaContract = kardiaClient.contract;
            kardiaContract.updateAbi(KRC20_API);
            const data = await kardiaContract.invokeContract("transfer", [toAddress, cellAmountDel]).txData();
            const kardiaTransaction = kardiaClient.transaction;
            const _gasPrice = await calGasprice(gasPrice)
            const response = await kardiaTransaction.sendTransactionToExtension({
                from: accounts[0],
                gasPrice: _gasPrice,
                gas: gasLimit,
                value: 0,
                data: data,
                to: tokenContract,
            }, true);
            ShowNotify(response);
        } catch (error) {
            console.error(error);
        }
    }
}

export {
    kardiaExtensionWalletEnabled,
    generateTxForEW,
    sendKRC20ByExtension
}