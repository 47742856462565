import { ethers } from "ethers";
import { useCallback } from "react";
import { calGasprice } from "../common";
import { GasMode } from "../enum";
import { getValidatorContract } from "../service/web3/contractHelpers";
import useActiveWeb3React from "./useActiveWeb3React";

export const useDelegate = () => {
    const { library } = useActiveWeb3React();
    const onDelegate = useCallback(async(address: string, amount: string, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const weiAmount = ethers.utils.parseEther(amount).toString()
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await validatorContract.delegate({value: weiAmount, gasLimit: gasLimit, gasPrice: _gasPrice});
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { onDelegate }
}