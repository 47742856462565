import React, { useState } from 'react';
import { Modal } from 'rsuite';
import { InforMessage, Button, ShowNotifyErr, ShowNotify, gasLimitDefault } from '../../../../common';
import { useRecoilValue } from 'recoil';
import walletState from '../../../../atom/wallet.atom';
import { isExtensionWallet, startValidator } from '../../../../service';
import { useStartValidator } from '../../../../hooks';
import { GasMode } from '../../../../enum';

const VaidatorStart = ({ validator = {} as Validator, showModel, setShowModel, reFetchData }: {
    validator: Validator;
    showModel: boolean;
    setShowModel: (isShow: boolean) => void;
    reFetchData: () => void;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const walletLocalState = useRecoilValue(walletState);

    const {start: startValidatorByEx} = useStartValidator()
    const start = async () => {
        try {
            const valSmcAddr = validator?.smcAddress || "";
            if (!valSmcAddr) {
                return false;
            }
            setIsLoading(true);
            const result = isExtensionWallet() ? await startValidatorByEx(valSmcAddr, gasLimitDefault, GasMode.NORMAL) :  await startValidator(valSmcAddr, walletLocalState.account);
            setIsLoading(false)
            ShowNotify(result)
            setShowModel(false)
            reFetchData();
        } catch (error) {
            ShowNotifyErr(error)
        }
        setIsLoading(false);
        setShowModel(false);
    }
    
    return (
        <Modal backdrop="static" size="sm" enforceFocus={true} show={showModel} onHide={() => { setShowModel(false) }}>
            <Modal.Header>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="confirm-letter" style={{ textAlign: 'center' }}>
                    {InforMessage.ConfirmStartingValidator}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="kai-button-gray" onClick={() => { setShowModel(false) }}>
                    Cancel
                </Button>
                <Button loading={isLoading} onClick={start}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default VaidatorStart;