import { useMemo } from "react"
import { getProposalContract, getStakingContract, getValidatorContract } from "../service/web3/contractHelpers"
import useActiveWeb3React from "./useActiveWeb3React"

export const useStakingContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getStakingContract(library?.getSigner()), [library])
}

export const useValidatorContract = (address: string) => {
    const { library } = useActiveWeb3React();
    return useMemo(() => getValidatorContract(address, library?.getSigner()), [library, address])
}

export const useProposalContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getProposalContract(library?.getSigner()), [library])
}