import { KAI_CHAINID_MAINET_V2 } from "../../common"
import { RPC_ENDPOINT } from "../../config"

const mainnetLink = process.env.REACT_APP_MAINNET_LINK || 'https://explorer.kardiachain.io'


export const setupNetwork = async () => {
    const provider = (window as WindowChain).ethereum
    if (provider) {
      const chainId = KAI_CHAINID_MAINET_V2
      try {
        await (provider as any).request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chainId.toString(16)}`,
              chainName: 'KardiaChain',
              nativeCurrency: {
                name: 'KAI',
                symbol: 'kai',
                decimals: 18
              },
              rpcUrls: [RPC_ENDPOINT],
              blockExplorerUrls: [mainnetLink],
            },
          ],
        })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    } else {
      console.error("Can't setup the KAI network on metamask because window.ethereum is undefined")
      return false
    }
}