import { ethers } from "ethers"
import STAKING_ABI  from "./../../resources/smc-compile/staking-abi.json"
import VALIDATOR_ABI  from "./../../resources/smc-compile/validator-abi.json"
import PROPOSAL_ABI from "./../../resources/smc-compile/proposal-abi.json"
import { STAKING_SMC_ADDRESS, PROPOSAL_SMC_ADDRESS } from "../../config"
import { simpleRpcProvider } from "./providers"

export const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    const signerOrProvider = signer ?? simpleRpcProvider
    return new ethers.Contract(address, abi, signerOrProvider)
}

export const getContractFactory = (abi: any, byteCode: any, signer?: ethers.Signer | ethers.providers.Provider) => {
    const signerOrProvider = signer ?? simpleRpcProvider
    return new ethers.ContractFactory(abi, byteCode, signerOrProvider as any);
}

export const getStakingContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(STAKING_ABI, STAKING_SMC_ADDRESS, signer)
}

export const getValidatorContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(VALIDATOR_ABI, address, signer)
}

export const getProposalContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
    return getContract(PROPOSAL_ABI, PROPOSAL_SMC_ADDRESS, signer)
}
