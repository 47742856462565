import { KaiConnector } from "@becoswap-libs/kai-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { ethers } from "ethers";

const KAI_CHAINID_MAINET_V2 = Number(process.env.REACT_APP_KAI_CHAINID) || 24;
const KAI_CHAINID_MAINET_V1 = 0;
const injected = new InjectedConnector({ supportedChainIds: [KAI_CHAINID_MAINET_V2] });
const kaiConnector = new KaiConnector({ supportedChainIds: [KAI_CHAINID_MAINET_V2, KAI_CHAINID_MAINET_V1] });

export enum ConnectorNames {
    Injected = "injected",
    KAI = "KAI"
}
export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.KAI]: kaiConnector,
}

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
    const library = new ethers.providers.Web3Provider(provider)
    library.pollingInterval = 12000
    return library
}