import { ethers } from "ethers";
import { useCallback } from "react";
import { calGasprice, gasLimitDefault } from "../common";
import { GasMode } from "../enum";
import { getContract, getContractFactory } from "../service/web3/contractHelpers";
import useActiveWeb3React from "./useActiveWeb3React";



export const useInteractSMC = () => {
    const { library } = useActiveWeb3React();
    const interact = useCallback(async ({ abi, smcAddr, methodName, params, amount = 0, gasLimit = gasLimitDefault, gasPrice = GasMode.NORMAL }: {
        abi: any;
        smcAddr: string;
        methodName: string;
        params: any[];
        amount?: number;
        gasLimit?: number;
        gasPrice?: GasMode;
    }) => {
        const contract = getContract(abi, smcAddr, library?.getSigner())
        const weiAmount = ethers.utils.parseEther(amount.toString()).toString()
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await contract[methodName](...params, { value: weiAmount, gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { interact }
}

export const useDeployContract = () => {
    const { library } = useActiveWeb3React();
    const deploy = useCallback(async ({ abi, bytecode, params, amount = 0, gasLimit, gasPrice }: {
        abi: any;
        bytecode: any;
        params: any;
        amount?: number;
        gasLimit: number;
        gasPrice: GasMode;
    }) => {
        const factory = getContractFactory(abi, {object:  bytecode}, library?.getSigner())
        let contract = await factory.deploy(...(params || []));
        await contract.deployed()
        return contract.deployTransaction.wait()
    }, [library]);
    return { deploy }
}