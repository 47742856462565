import { ethers } from "ethers";
import { useCallback } from "react";
import { calGasprice } from "../common";
import { GasMode } from "../enum";
import { useProposalContract } from "./useContract";


export const useAddProposal = () => {
    const proposalContract = useProposalContract()
    const addProposal = useCallback(async (paramsKey: any[], paramsValue: any[], gasLimit: number, gasPrice: GasMode) => {
        const _gasPrice = await calGasprice(gasPrice)
        const weiAmount = ethers.utils.parseEther("500000").toString()
        const tx = await proposalContract.addProposal(
            paramsKey,
            paramsValue,
            {value: weiAmount, gasLimit: gasLimit, gasPrice: _gasPrice }
        );
        const receipt = await tx.wait();
        return receipt
    }, [proposalContract]);
    return { addProposal }
}

export const useProposalVoting = () => {
    const proposalContract = useProposalContract()
    const vote = useCallback(async (proposalId: number, voteOption: number, gasLimit: number, gasPrice: GasMode) => {
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await proposalContract.addVote(
            proposalId,
            voteOption,
            { gasLimit: gasLimit, gasPrice: _gasPrice }
        );
        const receipt = await tx.wait();
        return receipt
    }, [proposalContract]);
    return { vote }
}

