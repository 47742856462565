import { ethers } from "ethers";
import { useCallback } from "react";
import { calGasprice } from "../common";
import { GasMode } from "../enum";
import { getValidatorContract } from "../service/web3/contractHelpers";
import useActiveWeb3React from "./useActiveWeb3React";
import { useStakingContract } from "./useContract";


export const useCreateValidator = () => {
    const stakingContract = useStakingContract()
    const createValidator = useCallback(async (params: CreateValParams, gasLimit: number, gasPrice: GasMode) => {
        const _gasPrice = await calGasprice(gasPrice)
        const valName = ethers.utils.formatBytes32String(params.valName);
        const commissionRateDec = ethers.utils.parseEther((params.commissionRate / 100).toString()).toString();
        const maxRateDec = ethers.utils.parseEther((params.maxRate / 100).toString()).toString();
        const maxRateChangeDec = ethers.utils.parseEther((params.maxChangeRate / 100).toString()).toString();
        const delAmountDec = ethers.utils.parseEther(params.yourDelegationAmount.toString()).toString();
        const tx = await stakingContract.createValidator(
            valName,
            commissionRateDec,
            maxRateDec,
            maxRateChangeDec,
            { value: delAmountDec, gasLimit: gasLimit, gasPrice: _gasPrice }
        );
        const receipt = await tx.wait();
        return receipt
    }, [stakingContract]);
    return { createValidator }
}

export const useUpdateValidatorName = () => {
    const { library } = useActiveWeb3React();
    const updateValidatorName = useCallback(async (address: string, name: string, amountFee: number, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const weiFeeAmount = ethers.utils.parseEther(amountFee.toString()).toString()
        const _gasPrice = await calGasprice(gasPrice)
        const valName = ethers.utils.formatBytes32String(name);
        const tx = await validatorContract.updateName(valName, { value: weiFeeAmount, gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { updateValidatorName }
}

export const useUpdateValidatorCommission = () => {
    const { library } = useActiveWeb3React();
    const update = useCallback(async (address: string, newCommissionRate: number, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const newCommissionRateDec = ethers.utils.parseEther((newCommissionRate / 100).toString()).toString()
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await validatorContract.updateCommissionRate(newCommissionRateDec, { gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { update }
}

export const useStartValidator = () => {
    const { library } = useActiveWeb3React();
    const start = useCallback(async (address: string, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await validatorContract.start({ gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { start }
}

export const useWithdrawCommission = () => {
    const { library } = useActiveWeb3React();
    const withdraw = useCallback(async (address: string, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await validatorContract.withdrawCommission({ gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { withdraw }
}

export const useWithdrawRewards = () => {
    const { library } = useActiveWeb3React();
    const withdraw = useCallback(async (address: string, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await validatorContract.withdrawRewards({ gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { withdraw }
}

export const useWithdrawDelegateAmount= () => {
    const { library } = useActiveWeb3React();
    const withdraw = useCallback(async (address: string, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await validatorContract.withdraw({ gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { withdraw }
}

export const useUndelegateWithAmount = () => {
    const { library } = useActiveWeb3React();
    const undelegate = useCallback(async (address: string, amountUndel: number, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const amountUndelDec = ethers.utils.parseEther(amountUndel.toString()).toString()
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await validatorContract.undelegateWithAmount(amountUndelDec, { gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { undelegate }
}

export const useUndelegateAll = () => {
    const { library } = useActiveWeb3React();
    const undelegate = useCallback(async (address: string, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await validatorContract.undelegate({ gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { undelegate }
}

export const useUnjailValidator = () => {
    const { library } = useActiveWeb3React();
    const unjail = useCallback(async (address: string, gasLimit: any, gasPrice: GasMode) => {
        const validatorContract = getValidatorContract(address, library?.getSigner())
        const _gasPrice = await calGasprice(gasPrice)
        const tx = await validatorContract.unjail({ gasLimit: gasLimit, gasPrice: _gasPrice });
        const receipt = await tx.wait();
        return receipt
    }, [library]);
    return { unjail }
}