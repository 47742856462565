import { NoBscProviderError } from "@becoswap-libs/kai-connector"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from "@web3-react/injected-connector"
import { useCallback } from "react"
import { Alert } from "rsuite"
import { ConnectorNames, connectorsByName, setupNetwork } from "./../service"


export const useAuth = () => {
    const { activate, deactivate } = useWeb3React()
    const login = useCallback((connectorID: ConnectorNames) => {
        const connector = connectorsByName[connectorID]
        if (connector) {
            activate(connector, async (error: Error) => {
                if (error instanceof UnsupportedChainIdError) {
                    const hasSetup = await setupNetwork()
                    if (hasSetup) {
                      activate(connector)
                    }
                } else {
                    if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
                        Alert.error('Provider Error - No provider was found', 5000)
                    } else if (error instanceof UserRejectedRequestErrorInjected) {
                        Alert.error('Authorization Error - Please authorize to access your account', 5000)
                    } else {
                        Alert.error(error.name + '-' + error.message, 5000)
                    }
                }
            });
        } else {
            Alert.error("Can't find connector - The connector config is wrong", 5000);
        }
    }, [])

    const logout = useCallback(() => {
        deactivate()
    }, [deactivate])
    return { login, logout }
}